<template>
  <v-layout column fill-height class="text-only-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions-row">
      <v-layout column>
        <template v-if="!!instructionsPhotoUrl">
          <v-flex d-flex xs2>
            <ResizableText :message="serialized" />
          </v-flex>
          <v-flex
            xs10
            grow
            d-flex
            v-if="!!instructionsPhotoUrl"
            class="text-only-mission__image-asset-container"
            :style="`background-image: url(${instructionsPhotoUrl})`"
          />
        </template>
        <template v-else>
          <v-flex d-flex>
            <ResizableText :message="serialized" />
          </v-flex>
        </template>
      </v-layout>
    </v-flex>
    <v-flex v-if="isLinkMission && showLink">
      <h2>
        <span v-if="!!mission.showWinningTeam">Only Winning Team: </span>
        <a :href="mission.answer" target="_blank">{{ mission.answer }} </a>
      </h2>
    </v-flex>
    <!-- Answer -->
    <v-flex
      d-flex
      justify-center
      align-center
      class="text-only-mission__notes"
      v-if="isHost && notes"
    >
      Answer: {{ notes }}
    </v-flex>
  </v-layout>
</template>

<script>
import ResizableText from "./ResizableText.vue"
import { mapGetters } from "vuex"
const REGEX_EXTRACT_PATTERN = /<img.*?src="(.*?)"/
const REGEX_REPLACE_PATTERN = /<img[^>]*>/g

export default {
  name: "TextOnly",
  components: {
    ResizableText
  },
  computed: {
    ...mapGetters({
      teams: "chats",
      mission: "getCurrentMission"
    }),
    ...mapGetters("auth", ["user", "isHost"]),
    instructionsPhotoUrl() {
      return this.mission.instructionsPhoto || this.extractedUrl
    },
    extractedUrl() {
      return REGEX_EXTRACT_PATTERN.exec(this.instructions)?.[1]
    },
    notes() {
      return this.mission.notes
    },
    serialized() {
      return this.instructions.replace(REGEX_REPLACE_PATTERN, "")
    },
    instructions() {
      return this.mission.instructions.replace("/n", "<br/>")
    },
    isLinkMission() {
      return this.mission.behavior === "Link"
    },
    showLink() {
      return (
        (this.isLinkMission &&
          // let's not execute isWinningTeam is we don't have to
          (!this.mission.showWinningTeam ||
            (this.mission.showWinningTeam && this.isWinningTeam))) ||
        this.isHost
      )
    },
    isWinningTeam() {
      const [winningTeam] = Object.values(this.teams).sort(
        (a, b) => parseInt(b.totalScore) - parseInt(a.totalScore)
      )
      return winningTeam && this.user.teamID === winningTeam.id
    }
  }
}
</script>

<style lang="scss">
.text-only-mission {
  &__image-asset-container {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateZ(0);
  }
  &__notes {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 49px;
    padding: 0px 62px 0 62px;
    color: $primary_accent_color;
    line-height: 1;
    font-size: 13px;
  }
  .mission-instructions-row {
    padding: 25px 10px 49px;
  }
  .mission-instructions {
    color: $color-black;
    padding: 10px;
    font-size: 22px;
  }
}
</style>
