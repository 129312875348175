var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "text-only-mission",
      attrs: { column: "", "fill-height": "" }
    },
    [
      _c(
        "v-flex",
        { staticClass: "mission-instructions-row", attrs: { "d-flex": "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              !!_vm.instructionsPhotoUrl
                ? [
                    _c(
                      "v-flex",
                      { attrs: { "d-flex": "", xs2: "" } },
                      [
                        _c("ResizableText", {
                          attrs: { message: _vm.serialized }
                        })
                      ],
                      1
                    ),
                    !!_vm.instructionsPhotoUrl
                      ? _c("v-flex", {
                          staticClass:
                            "text-only-mission__image-asset-container",
                          style:
                            "background-image: url(" +
                            _vm.instructionsPhotoUrl +
                            ")",
                          attrs: { xs10: "", grow: "", "d-flex": "" }
                        })
                      : _vm._e()
                  ]
                : [
                    _c(
                      "v-flex",
                      { attrs: { "d-flex": "" } },
                      [
                        _c("ResizableText", {
                          attrs: { message: _vm.serialized }
                        })
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ],
        1
      ),
      _vm.isLinkMission && _vm.showLink
        ? _c("v-flex", [
            _c("h2", [
              !!_vm.mission.showWinningTeam
                ? _c("span", [_vm._v("Only Winning Team: ")])
                : _vm._e(),
              _c(
                "a",
                { attrs: { href: _vm.mission.answer, target: "_blank" } },
                [_vm._v(_vm._s(_vm.mission.answer) + " ")]
              )
            ])
          ])
        : _vm._e(),
      _vm.isHost && _vm.notes
        ? _c(
            "v-flex",
            {
              staticClass: "text-only-mission__notes",
              attrs: { "d-flex": "", "justify-center": "", "align-center": "" }
            },
            [_vm._v(" Answer: " + _vm._s(_vm.notes) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }