import { render, staticRenderFns } from "./TextOnly.vue?vue&type=template&id=42fcf7e3&"
import script from "./TextOnly.vue?vue&type=script&lang=js&"
export * from "./TextOnly.vue?vue&type=script&lang=js&"
import style0 from "./TextOnly.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/finnkelly/Code/Go Remote/remote-chat-finn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42fcf7e3')) {
      api.createRecord('42fcf7e3', component.options)
    } else {
      api.reload('42fcf7e3', component.options)
    }
    module.hot.accept("./TextOnly.vue?vue&type=template&id=42fcf7e3&", function () {
      api.rerender('42fcf7e3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/TextOnly.vue"
export default component.exports